.intro{
    height:90vh;
    margin-top: -40px;
    padding: 0;
    margin: 0;
    background-image: url("intro-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
}
.intro-details{
    max-width: 600px;
    padding: 100px;
    line-height: 30px;
}
.my-name{
    color: #6D83F2;
    font-size: 16px;
    font-family: 'Kanit', sans-serif;
}
.title{
    color: rgba(0, 0, 0, 0.755);
    font-family: 'Kanit', sans-serif;
    font-size: 45px;
}
.my-desc{
    color: #8892b0;
    font-family: 'Kanit', sans-serif;
}



@media screen and (max-width : 1150px) {
    .intro{
        display: flex;
        justify-content: stretch;
        align-items: center;
        padding-left: 0;
    }
    .my-name{
        font-size: 15px;
    }
    .intro-details{
        max-width: 50%;
    }
    .my-desc{
        font-size: 15px;
        line-height: 20px;
    }
    .title{
        font-size: 35px;
    }
}

@media screen and (max-width : 800px) {
    .intro{
        display: flex;
        justify-content: stretch;
        align-items: center;
        padding-left: 0%;
    }
    .my-name{
        font-size: 15px;
    }
    .intro-details{
        max-width: 40%;
    }
    .my-desc{
        font-size: 15px;
        line-height: 20px;
    }
    .title{
        font-size: 20px;
    }
}
@media screen and (max-width : 500px) {
    .intro{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 0%;
        background-image: url('img.png');
    }
    .intro-details{
        padding: 0;
    }
    .my-name{
        font-size: 15px;
    }
    .intro-details{
        max-width: 80%;
    }
    .my-desc{
        font-size: 15px;
        line-height: 20px;
    }
    .title{
        font-size: 30px;
        line-height: 40px;
    }
}

@media screen and (min-width : 1550px) {
    .intro{
        display: flex;
        justify-content: stretch;
        align-items: center;
        padding-left: 10%;
    }
    .my-name{
        font-size: 30px;
    }
    .intro-details{
        max-width: 40%;
    }
    .my-desc{
        font-size: 20px;
        line-height: 35px;
    }
    .title{
        font-size: 50px;
    }
}
@media screen and (min-width : 1750px) {
    .intro{
        display: flex;
        justify-content: stretch;
        align-items: center;
        padding-left: 10%;
    }
    .my-name{
        font-size: 30px;
    }
    .intro-details{
        max-width: 40%;
    }
    .my-desc{
        font-size: 30px;
        line-height: 45px;
    }
    .title{
        font-size: 50px;
    }
}

@media screen and (min-width : 2000px) {
    .intro{
        display: flex;
        justify-content: stretch;
        align-items: center;
        padding-left: 10%;
    }
    .my-name{
        font-size: 30px;
    }
    .intro-details{
        max-width: 40%;
    }
    .my-desc{
        font-size: 30px;
        line-height: 45px;
    }
    .title{
        font-size: 70px;
    }
}