.skills{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding-left: 10%;
    padding-right: 10%;
    color: #6D83F2;
    font-family: 'Kanit', sans-serif;
    background-image: url("skills.png");
    background-size: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    
}
.hr-line{
    width: 200px;
    height: 2px;
    background-color: #6D83F2;
    margin-left: 20px;
}
.skills-title{
    font-size: 50px;
}
.skill-list{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-top: -100px;
    height: 100%;

}
@media screen and (max-width:1200px) {
    .skills{
        background-image: url('img2.png');
    }
    .skill-list{
        align-items: center;
        margin-top: -100px;
    }
}
@media screen and (max-width: 800px){
    .skills{
        background-size: 100%;
        height: 80vh;
    }
    .skill-list{
        align-items: center;
        margin-top: -100px;
    }
    .skill-list img{
        width: 60px;
    }
    /* .skills-title{
        font-size: 35px;
        
    } */
}
@media screen and (max-width: 500px){
    .skills{
        background-size: 100%;
        background-image: none;
        height: 100vh;
    }
    .skill-list{
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 0;
    }
    .skill-list img{
        width: 100px;
        
    }
    .skills-title{
        font-size: 40px;
        
    }
    .hr-line{
        width: 100px;
    }
}

@media screen and (min-width: 2000px){
    .skills{

    }
    .skill-list{
        margin-top: 0;
    }
    .skill-list img{
        width: 200px;
    }
    .skills-title{
        font-size: 90px;
    }
}