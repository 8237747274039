.expr{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #6D83F2;
    font-family: 'Kanit', sans-serif;
}
.expr-wrap{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    color: black;
}
.expr-left li{
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 10px;
    border-left: 4px solid #6D83F2;
    display: flex;
    align-items: center;
    justify-content: center;

}
.expr-left li:hover{
    cursor: pointer;
}
.expr-left{
    /* padding-bottom:10px ;
    padding-left: 10px; */



    text-align: center;
}

.bold{
    font-weight: bolder;
}
.purple{
    color: #6D83F2;
}
.active{
    background-color: #6d83f20c;
    color: #6D83F2;
    box-shadow: 2px 2px  2px #6D83F2;
}
.point-ul{
    max-width: 600px;
    list-style: square;
    padding-left: 0;
}
.expr-left li:hover {
    background-color: #6d83f22c;
    cursor: pointer;
}
.point-ul li{
    padding-bottom: 20px;
    padding-left: 0;
}
.exp-title{
    font-size: 50px;
}
@media screen and (max-width:1300px){

    .expr-wrap{
        padding-left: 0;
        padding-right: 0;
    }
    
}
@media screen and (max-width:1000px) {
    .expr-wrap{
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding-left: 0;
        padding-right: 0;
    }
    .expr-left li{
        border: none;
    }
    .expr-left{
        width: 400px;
        display: flex;
        flex-direction: row;
        border-left: 1px white;
        border-top: 4px solid #6D83F2;
        justify-content: space-between;
        padding-left: 0;
    }
    
}

@media screen and (max-width:500px){
    .expr{
        max-width: 360px;
        margin-left: 10px;
    }
    .point-ul{
        max-width: 380px;
    }
    .expr-wrap{
        padding-left: 0;
        padding-right: 0;
    }
    .exp-title{
        font-size: 30px;
    }
    .expr-left{
        max-width: 360px;
    }
}