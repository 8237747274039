body{
    padding: 0;
    margin: 0;
}

.video{
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: rgb(43, 64, 83); */
    height: 100vh;
}